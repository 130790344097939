<template>
  <div>
    <div class="img-w-overlay" :style="`background: url(${bgUrl}); min-height: ${minHeight}`">
      <div class="overlay-effect h-100 w-100">
        <div :class="textClass">
          <div class="w-full p-12">
            <h1 :style="'color:'+textColor">{{ title }}</h1>
            <h3 :style="'color:'+textColor" class="mt-base">{{ subtitle }}</h3>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'image-overlay',
  props: {
    bgUrl: {
      type: String,
      required: true
    },
    title: {
      type: String,
    },
    subtitle: {
      type: String,
    },
    textCenter: {
      type: Boolean,
      default:false
    },
    textColor: {
      type: String,
      default:"white"
    },
    minHeight: {
      default:"300px"
    }
  },
  computed: {
    textClass() {
      return this.textCenter? "flex flex-col justify-center h-full text-center":"flex flex-col justify-center h-full";
    }
  }
}
</script>

<style>
.img-w-overlay {
  border-radius: 5px 5px 0 0;
  position: relative;
  background-size: cover !important;
}

.overlay-effect {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 1;
  overflow: hidden;
}
</style>